var toggleSrc = function(e) {
  var $element = $(e.target);
  var src = $element.attr('src');
  var srcState = src.indexOf('not-selected-state') !== -1 ? 'not-selected-state' : 'selected-state';

  if (srcState === 'not-selected-state') {
    src = $element.attr('data-selected');
  } else {
    src = $element.attr('data-not-selected');
  }
  $element.attr('src', src);
};

$(document).on('click', '.location_button', function(e) {
  var $images = $('.location_button');
  $images.removeClass('selected');
  $(e.target).toggleClass('selected');
  $images.each(function() {
    if (!$(this).hasClass('selected')) {
      $(this).attr('src', $(this).attr('data-not-selected'));
    }
  });
})
.on('mouseover', '.location_button', function(e) {
  if (!$(e.target).hasClass('selected')) {
    toggleSrc(e);
  }
})
.on('mouseout', '.location_button', function(e) {
  if (!$(e.target).hasClass('selected')) {
    toggleSrc(e);
  }
});
